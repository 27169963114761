import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react";
import * as React from "react";
import { loginRequest } from "../AuthConfig";

export default function Nav(): JSX.Element {
	const { instance, accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async () => await instance.loginRedirect(loginRequest);
	const signOut = async () => await instance.logoutRedirect();

	const account = accounts[0];

	const itemsNearAuthed: () => ICommandBarItemProps[] = () => [
		{
			key: "title",
			text: "Whiskey Bravo Apps",
			buttonStyles: {
				label: {
					fontWeight: "bold"
				}
			}
		},
		{
			key: "help",
			text: "Knowledgebase",
			iconProps: { iconName: "Lightbulb" },
			href: "https://whiskeybrav.sharepoint.com/sites/it",
			target: "_blank"
		},
		{
			key: "help",
			text: "Helpdesk",
			iconProps: { iconName: "Lifesaver" },
			href: "https://whiskeybrav.sharepoint.com/sites/it/SitePages/Get-help-now!.aspx",
			target: "_blank"
		},
	];

	const itemsNearUnauthed: ICommandBarItemProps[] = [
		{
			key: "title",
			text: "Whiskey Bravo Apps",
			buttonStyles: {
				label: {
					fontWeight: "bold"
				}
			}
		}
	];

	const itemsFarAuthed: () => ICommandBarItemProps[] = () => [
		{
			key: "userMenuAuthed",
			//@ts-expect-error typings are incomplete
			text: account.name,
			iconProps: { iconName: "IDBadge" },
			subMenuProps: {
				items: [
					{
						key: "email",
						iconProps: { iconName: "Mail" },
						text: account.username,
						disabled: true
					},
					{
						key: "manage",
						iconProps: { iconName: "Settings" },
						text: "Account settings",
						href: "https://myaccount.microsoft.com/?tenant=whiskeybravo.org",
						target: "_blank"
					},
					{
						key: "pwChange",
						iconProps: { iconName: "Lock" },
						text: "Change password",
						href: "https://account.activedirectory.windowsazure.com/ChangePassword.aspx",
						target: "_blank",
					},
					{
						key: "logout",
						iconProps: { iconName: "SignOut" },
						text: "Log out",
						//@ts-expect-error it doesn't matter that this is a promise 
						onClick: signOut,
					}
				]
			}
		}
	];

	const itemsFarUnauthed: ICommandBarItemProps[] = [
		{
			key: "userMenuUnauthed",
			text: "Log in",
			iconProps: { iconName: "IDBadge" },
			//@ts-expect-error it doesn't matter that this is a promise 
			onClick: signIn,
		}
	];

	return <>
		<CommandBar
			items={isAuthenticated ? itemsNearAuthed() : itemsNearUnauthed}
			farItems={isAuthenticated ? itemsFarAuthed() : itemsFarUnauthed}
		/>
	</>;
}