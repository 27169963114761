import { Depths, Icon, IStackTokens, Stack, Image, Text, Link } from "@fluentui/react";
import * as React from "react";

import "./AppStack.sass";

// Tokens definition
const sectionStackTokens: IStackTokens = { childrenGap: 10 };
const wrapStackTokens: IStackTokens = { childrenGap: 30, padding: 10 };

const msIconURL = (icon: string) => `https://static2.sharepointonline.com/files/fabric-cdn-prod_20200430.002/assets/brand-icons/product/svg/${icon.toLowerCase()}_48x1.svg`;
interface appStackProps {
	apps: AppItemProps[]
}

export default function AppStack(props: appStackProps): JSX.Element {

	return <Stack tokens={sectionStackTokens}>
		<Stack horizontal wrap tokens={wrapStackTokens} horizontalAlign="center">
			{props.apps.map((item, i) => <AppItem {...item} key={i} />)}
		</Stack>
	</Stack>;
}

export interface AppItemProps {
	name: string
	icon: string
	iconType: "image" | "office-brand-icon" | "workspace-brand-icon" | "fluent-icon"
	url: string
}

function AppItem(props: AppItemProps): JSX.Element {
	const [isHover, setIsHover] = React.useState(false);

	return <Link href={props.url}>
		<Stack
			style={isHover ? { boxShadow: Depths.depth8 } : {}}
			className="app-item"
			horizontalAlign="center"
			verticalAlign="center"
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			{props.iconType == "fluent-icon" && <div className="icon-box">
				<Icon iconName={props.icon} />
			</div>}
			{props.iconType == "image" && <Image src={props.icon} height={64} />}
			{props.iconType == "office-brand-icon" && <Image src={msIconURL(props.icon)} height={64} />}
			{props.iconType == "workspace-brand-icon" && <Image src={`https://www.gstatic.com/images/branding/product/2x/hh_${props.icon.toLowerCase()}_64dp.png`} height={64} />}
			<Text className="pt-2">{props.name}</Text>
		</Stack>
	</Link>;
}