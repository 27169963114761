/* eslint-disable no-console */
import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
	auth: {
		clientId: process.env.CLIENT_ID,
		authority: process.env.AUTHORITY,
		redirectUri: process.env.REDIRECT_URI
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			}
		}
	}
};
export const loginRequest = {
	scopes: ["User.Read"]
};
export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
