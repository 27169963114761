import { Link, MessageBar, MessageBarType, Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import AppStack from "./AppStack";
import { apps } from "./apps";

interface mainPivotProps {
	email: string
}

export default function MainPivot(props: mainPivotProps): JSX.Element {
	const all = apps(props.email);

	const isSetup = window.location.hash == "#accountSetup";

	return <>
		{isSetup && <MessageBar
			messageBarType={MessageBarType.success}
			isMultiline={true}
		>
			Welcome to your new Whiskey Bravo account. To access your email, click "Gmail." More tools, like Slack, Google Meet, and Microsoft 365 are available under the "Communicate" and "Create" tabs.
		</MessageBar>
		}
		<Pivot aria-label="Basic Pivot Example">
			<PivotItem headerText="Featured" itemIcon="FavoriteStar">
				<AppStack apps={all.filter(app => app.categories.indexOf("featured") != -1)} />
			</PivotItem>
			<PivotItem headerText="Communicate" itemIcon="OfficeChat">
				<AppStack apps={all.filter(app => app.categories.indexOf("communicate") != -1)} />
			</PivotItem>
			<PivotItem headerText="Create" itemIcon="TextDocumentShared">
				<AppStack apps={all.filter(app => app.categories.indexOf("create") != -1)} />
			</PivotItem>
			<PivotItem headerText="Account" itemIcon="AccountManagement">
				<AppStack apps={all.filter(app => app.categories.indexOf("account") != -1)} />
			</PivotItem>
			<PivotItem headerText="All Apps" itemIcon="AllApps">
				<AppStack apps={all} />
			</PivotItem>
		</Pivot>
	</>;

}