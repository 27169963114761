import { Link, Panel, PanelType, PrimaryButton, Stack, Text, TextField } from "@fluentui/react";
import * as React from "react";
import Question, { answer } from "./Question";

interface loginPanelProps {
	isOpen: boolean;
	dismiss(): void;
}

const generatePasswordResetUrl = (email: string): string => `https://passwordreset.microsoftonline.com/?username=${encodeURIComponent(email)}`;

export default function LoginPanel(props: loginPanelProps): JSX.Element {
	const [emailAccess, setEmailAccess] = React.useState("unknown" as answer);

	return <Panel
		isOpen={props.isOpen}
		onDismiss={props.dismiss}
		onOuterClick={props.dismiss}
		type={PanelType.medium}
		headerText="Troubleshooting login issues"
		isLightDismiss>
		<Stack tokens={{ childrenGap: 10 }}>
			<p>
				<Text>Hi! Everyone has login isses sometimes, so let's get you back on your feet.</Text>
			</p>
			<p>
				<Text>This troubleshooter will ask a few questions, then reccomend a solution. Let's get started.</Text>
			</p>
			<p>
				<Text>Sometimes, even if you can't log in, you might have access to your @whiskeybravo.org email through a phone or other device.</Text>
			</p>
			<Question
				question="Do you have access to your @whiskeybravo.org email address?"
				answer={emailAccess}
				setAnswer={setEmailAccess}
			/>
			{emailAccess == "yes" && <LoginPanelEmailFlow />}
			{emailAccess == "no" && <NoSSPR />}
		</Stack>
	</Panel>;
}

function LoginPanelEmailFlow(): JSX.Element {
	const [isO365Admin, setIsO365Admin] = React.useState("unknown" as answer);
	const [isGoogleAdmin, setIsGoogleAdmin] = React.useState("unknown" as answer);

	return <>
		<p>
			<Text>Depending on your account's type, you may have different password reset options.</Text>
		</p>
		<p>
			<Text>You'll know if you should answer yes to any of the following questions. If you don't know what a question means, answer no.</Text>
		</p>
		<Question
			question="Are you an administrator for Google Workspace at Whiskey Bravo (admin.google.com)?"
			answer={isGoogleAdmin}
			setAnswer={setIsGoogleAdmin}
		/>
		{ isGoogleAdmin != "unknown" &&
			<Question
				question="Are you an administrator for Microsoft 365, Microsoft Office, SharePoint, Azure Active Directory, or Exchange at Whiskey Bravo (admin.microsoft.com)?"
				answer={isO365Admin}
				setAnswer={setIsO365Admin}
			/>
		}
		{isO365Admin == "yes" && isGoogleAdmin != "unknown" && <SSPR />}
		{isGoogleAdmin == "yes" && isO365Admin == "no" && <EmailReset />}
		{isGoogleAdmin == "no" && isO365Admin == "no" && <EmailReset />}
	</>;
}

function NoSSPR(): JSX.Element {
	return <>
		<Text>Unfortunatly, based on the options you've chosen, self-service password reset isn't an option.</Text>
		<Text>You can however submit a password-reset request. To get started with your request, enter your email address below.</Text>
		<SSPRLinkGen />
	</>;
}

function EmailReset(): JSX.Element {
	return <>
		<Text>Based on your answers, you can submit an email password reset request.</Text>
		<Text>Send an email to <Link href="mailto:it@whiskeybravo.org">it@whiskeybravo.org</Link> requesting that your password be reset. Make sure that you send it from your @whiskeybravo.org email. We'll be in touch shortly.</Text>
		<Stack horizontal>
			<PrimaryButton href="mailto:it@whiskeybravo.org" iconProps={{ iconName: "Mail" }}>Send email</PrimaryButton>
		</Stack>
	</>;
}

function SSPR(): JSX.Element {
	return <>
		<Text>Based on your answers, we've determined that your account is eligible for a self-service password reset. This will allow you to get up and running in a jiffy!</Text>
		<SSPRLinkGen />
	</>;
}

function SSPRLinkGen(): JSX.Element {
	const [emailPrefix, setEmailPrefix] = React.useState("");
	const [err, setErr] = React.useState("");

	const updateEmail = (nv: string) => {
		if (nv.indexOf("@") != -1) {
			setErr("Make sure to omit the @whiskeybravo.org. It's already included.");
		} else {
			setErr("");
		}
		setEmailPrefix(nv);
	};

	return <>
		<TextField
			label="Email address"
			suffix="@whiskeybravo.org"
			ariaLabel="Email address"
			value={emailPrefix}
			onChange={(_ev, nv) => updateEmail(nv)}
			errorMessage={err}
		/>
		<Stack horizontal>
			<PrimaryButton
				href={generatePasswordResetUrl(emailPrefix + "@whiskeybravo.org")}
				target="_blank"
				iconProps={{ iconName: "LifesaverLock" }}
				disabled={emailPrefix == "" || err != ""}
			>Reset my password</PrimaryButton>
		</Stack>
	</>;
}