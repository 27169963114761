import { ITooltipHostProps, TooltipHost } from "@fluentui/react";
import * as React from "react";

interface conditionalTooltipProps extends ITooltipHostProps {
	condition: boolean;
	children: React.ReactNode
}

const ConditionalTooltip = (props: conditionalTooltipProps): JSX.Element => (props.condition ?
	<TooltipHost {...props}>{props.children}</TooltipHost> :
	<>{props.children}</>);

export default ConditionalTooltip;