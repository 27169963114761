import { useMsal } from "@azure/msal-react";
import * as React from "react";
import Header from "./Header";
import MainPivot from "./MainPivot";
export default function Authenticated(): JSX.Element {
	const { accounts } = useMsal();

	return <div className="container">
		<Header />
		<MainPivot email={accounts[0].username} />
	</div>;
}