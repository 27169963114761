//@ts-expect-error because typescript has no idea how to handle images
import SlackLogo from "./serviceimg/slack.png";
//@ts-expect-error because typescript has no idea how to handle images
import WhiteboardLogo from "./serviceimg/whiteboard.svg";
//@ts-expect-error because typescript has no idea how to handle images
import PlannerLogo from "./serviceimg/planner.svg";
//@ts-expect-error because typescript has no idea how to handle images
import AzureLogo from "./serviceimg/azure.png";
//@ts-expect-error because typescript has no idea how to handle images
import GitHubLogo from "./serviceimg/github.png";

import { AppItemProps } from "./AppStack";

type appCategory = "featured" | "communicate" | "create" | "account"

interface categorizedAppItem extends AppItemProps {
	categories: appCategory[]
}

export const apps: (email: string) => categorizedAppItem[] = (email: string) => [
	{
		name: "Gmail",
		icon: "Gmail",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://mail.google.com",
		categories: ["featured", "communicate"]
	},
	{
		name: "Slack",
		icon: SlackLogo,
		iconType: "image",
		url: "https://whiskeybravo.slack.com/sso/google/start",
		categories: ["featured", "communicate"]
	},
	{
		name: "Google Meet",
		icon: "Meet",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://meet.google.com",
		categories: ["featured", "communicate"]
	},
	{
		name: "Google Chat",
		icon: "chat",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://chat.google.com",
		categories: ["communicate"]
	},
	{
		name: "Microsoft Teams",
		icon: "teams",
		iconType: "office-brand-icon",
		url: `https://aka.ms/mstfw?login_hint_safe=${email}`,
		categories: ["communicate"]
	},
	{
		name: "Yammer",
		icon: "Yammer",
		iconType: "office-brand-icon",
		url: `https://yammer.com/login?username=${email}`,
		categories: ["communicate"]
	},
	{
		name: "Google Currents",
		icon: "Currents",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://currents.google.com",
		categories: ["communicate"]
	},
	{
		name: "Google Drive",
		icon: "Drive",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://drive.google.com",
		categories: ["create", "featured"]
	},
	{
		name: "Microsoft OneDrive",
		icon: "OneDrive",
		iconType: "office-brand-icon",
		url: `https://onedrive.com/login?username=${email}`,
		categories: ["create", "communicate", "featured"]
	},
	{
		name: "Microsoft Office",
		icon: "Office",
		iconType: "office-brand-icon",
		url: `https://office.com/login?username=${email}`,
		categories: ["create", "featured"]
	},
	{
		name: "Google Docs",
		icon: "Docs",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://docs.google.com",
		categories: ["create"]
	},
	{
		name: "Microsoft Word",
		icon: "Word",
		iconType: "office-brand-icon",
		url: `https://www.office.com/launch/word?auth=2&username=${email}&login_hint=${email}`,
		categories: ["create"]
	},
	{
		name: "Google Sheets",
		icon: "Sheets",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://sheets.google.com",
		categories: ["create"]
	},
	{
		name: "Microsoft Excel",
		icon: "Excel",
		iconType: "office-brand-icon",
		url: `https://www.office.com/launch/excel?auth=2&username=${email}&login_hint=${email}`,
		categories: ["create"]
	},
	{
		name: "Google Slides",
		icon: "Slides",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://slides.google.com",
		categories: ["create"]
	},
	{
		name: "Microsoft PowerPoint",
		icon: "PowerPoint",
		iconType: "office-brand-icon",
		url: `https://www.office.com/launch/powerpoint?auth=2&username=${email}&login_hint=${email}`,
		categories: ["create"]
	},
	{
		name: "Google Forms",
		icon: "Forms",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://forms.google.com",
		categories: ["create"]
	},
	{
		name: "Microsoft Forms",
		icon: "Forms",
		iconType: "office-brand-icon",
		url: `https://www.office.com/launch/forms?auth=2&username=${email}&login_hint=${email}`,
		categories: ["create"]
	},
	{
		name: "Jamboard",
		icon: "Jamboard",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://jamboard.google.com",
		categories: ["create"]
	},
	{
		name: "Microsoft Whiteboard",
		icon: WhiteboardLogo,
		iconType: "image",
		url: `https://whiteboard.microsoft.com/?source=applauncher&auth_upn=${email}`,
		categories: ["create"]
	},
	{
		name: "Google Sites",
		icon: "Sites",
		iconType: "workspace-brand-icon",
		url: "https://www.google.com/a/whiskeybravo.org/ServiceLogin?continue=https://sites.google.com",
		categories: ["create", "communicate"]
	},
	{
		name: "Microsoft Sway",
		icon: "Sway",
		iconType: "office-brand-icon",
		url: "https://www.office.com/launch/sway?auth=2&username=${email}&login_hint=${email}",
		categories: ["create", "communicate"]
	},
	{
		name: "SharePoint",
		icon: "SharePoint",
		iconType: "office-brand-icon",
		url: "https://whiskeybrav.sharepoint.com",
		categories: ["create", "communicate"]
	},
	{
		name: "Microsoft OneNote",
		icon: "OneNote",
		iconType: "office-brand-icon",
		url: `https://www.office.com/launch/onenote?auth=2&username=${email}&login_hint=${email}`,
		categories: ["create"]
	},
	{
		name: "Microsoft Project",
		icon: "project",
		iconType: "office-brand-icon",
		url: "https://project.microsoft.com?mkt=en-US",
		categories: ["create"]
	},
	{
		name: "Planner",
		icon: PlannerLogo,
		iconType: "image",
		url: `https://tasks.office.com?auth_pvr=OrgId&auth_upn=${email}`,
		categories: ["create"]
	},
	{
		name: "Microsoft Visio",
		icon: "visio",
		iconType: "office-brand-icon",
		url: `https://www.office.com/launch/visio?auth=2&username=${email}&login_hint=${email}`,
		categories: ["create"]
	},
	{
		name: "Microsoft Access",
		icon: "access",
		iconType: "office-brand-icon",
		url: `https://www.office.com/launch/access?auth=2&username=${email}&login_hint=${email}`,
		categories: ["create"]
	},
	{
		name: "Microsoft Stream",
		icon: "stream",
		iconType: "office-brand-icon",
		url: `https://web.microsoftstream.com/?auth_upn=${email}`,
		categories: ["create"]
	},
	{
		name: "Microsoft Delve",
		icon: "delve",
		iconType: "office-brand-icon",
		url: "https://whiskeybrav-my.sharepoint.com/_layouts/15/me.aspx?origin=shell",
		categories: ["create"]
	},
	{
		name: "Power Apps",
		icon: "https://flow.microsoft.com/images/application-logos/svg/powerapps.svg",
		iconType: "image",
		url: "https://make.powerapps.com",
		categories: ["create"]
	},
	{
		name: "Power Apps",
		icon: "https://powerautomate.microsoft.com/images/application-logos/svg/powerapps.svg",
		iconType: "image",
		url: "https://make.powerapps.com/",
		categories: ["create"]
	},
	{
		name: "Power Automate",
		icon: "https://powerapps.microsoft.com/images/application-logos/svg/powerautomate.svg",
		iconType: "image",
		url: `https://flow.microsoft.com/manage?auth_upn=${email}`,
		categories: ["create"]
	},
	{
		name: "Microsoft Azure",
		icon: AzureLogo,
		iconType: "image",
		url: "https://portal.azure.com/whiskeybravo.org",
		categories: ["create"]
	},
	{
		name: "GitHub",
		icon: GitHubLogo,
		iconType: "image",
		url: "https://github.com/whiskeybrav",
		categories: ["create"]
	},
	{
		name: "Account Settings",
		icon: "Settings",
		iconType: "fluent-icon",
		url: "https://myaccount.microsoft.com/?tenant=whiskeybravo.org",
		categories: ["account"]
	},
	{
		name: "Change Password",
		icon: "Lock",
		iconType: "fluent-icon",
		url: "https://account.activedirectory.windowsazure.com/ChangePassword.aspx",
		categories: ["account"]
	},
	{
		name: "IT Knowledgebase",
		icon: "Lightbulb",
		iconType: "fluent-icon",
		url: "https://whiskeybrav.sharepoint.com/sites/it",
		categories: ["account"]
	},
	{
		name: "IT Helpdesk",
		icon: "Lifesaver",
		iconType: "fluent-icon",
		url: "https://whiskeybrav.sharepoint.com/sites/it/SitePages/Get-help-now!.aspx",
		categories: ["account"]
	}
];
