import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import * as React from "react";

interface questionProps {
	question: string
	answer: answer
	setAnswer(ans: answer): void
}

export type answer = "unknown" | "yes" | "no"

const options: IChoiceGroupOption[] = [
	{ key: "yes" as answer, text: "Yes", iconProps: { iconName: "StatusCircleCheckmark" } },
	{ key: "no" as answer, text: "No", iconProps: { iconName: "StatusCircleErrorX" } },
];

export default function Question(props: questionProps): JSX.Element {
	return <ChoiceGroup
		label={props.question}
		options={options}
		onChange={(_ev, opt) => props.setAnswer(opt.key as answer)}
		selectedKey={props.answer == "unknown" ? undefined : props.answer}
	/>;
}