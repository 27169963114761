import { useMsal } from "@azure/msal-react";
import { Stack, Text } from "@fluentui/react";
import * as React from "react";

export default function Header(): JSX.Element {
	const { accounts } = useMsal();
	const account = accounts[0];
	//@ts-expect-error typings are incomplete
	const name: string = account.name;

	const greetings = ["Hello", "Howdy", "Heya"];
	const randomElement = <T extends unknown>(arr: T[]): T => arr[Math.floor(Math.random() * arr.length)];

	return <Stack>
		<Text variant="xLargePlus" className="pt-5" color="white">{randomElement(greetings)}, {name.split(" ")[0]}</Text>
		<Text variant="xLarge" className="pb-5" color="white">Welcome to Whiskey Bravo internal tools.</Text>
	</Stack>;
}