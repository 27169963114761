import * as React from "react";
import { msalConfig } from "../AuthConfig";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";


import "./App.sass";
import { PublicClientApplication } from "@azure/msal-browser";
import Nav from "./Nav";
import Unatuhenticated from "./unauthenticated/Unauthenticated";
import Authenticated from "./authenticated/Authenticated";
import { initializeIcons } from "@fluentui/react";

const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

export default function App(): JSX.Element {
	return <MsalProvider instance={msalInstance}>
		<Nav />
		<UnauthenticatedTemplate>
			<Unatuhenticated />
		</UnauthenticatedTemplate>
		<AuthenticatedTemplate>
			<Authenticated />
		</AuthenticatedTemplate>
	</MsalProvider>;
}