import { useMsal } from "@azure/msal-react";
import { Stack, Text, PrimaryButton, DefaultButton, Icon, MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { loginRequest } from "../../AuthConfig";
import ConditionalTooltip from "../ConditionalTooltip";
import LoginPanel from "./LoginPanel";

import "./Unauthenticated.sass";

export default function Unauthenticated(): JSX.Element {
	const { instance } = useMsal();

	const [loginPanelOpen, setLoginPanelOpen] = React.useState(false);

	const signIn = async () => await instance.loginRedirect(loginRequest);
	const issues = () => setLoginPanelOpen(!loginPanelOpen);

	const isSetup = window.location.hash == "#accountSetup";

	return <Stack
		verticalAlign="center"
		horizontalAlign="center"
		verticalFill
	>
		<div className="main-icon">
			<Icon iconName={"AllApps"} />
		</div>
		<Text variant="xxLargePlus">Whiskey Bravo Apps</Text>
		<Text variant="large">Internal tools for the Whiskey Bravo Team.</Text>
		{isSetup && <Stack tokens={{ maxWidth: "960px", padding: 20 }}>
			<MessageBar
				messageBarType={MessageBarType.info}
				isMultiline={true}
			>
				Click "Set up account" and log in with your new @whiskeybravo.org email address and your temporary password to get set up.
			</MessageBar>
		</Stack>
		}
		<Stack horizontal tokens={{ childrenGap: 20, padding: 10 }}>
			<PrimaryButton text={isSetup ? "Set up account" : "Log in"} onClick={signIn} allowDisabledFocus />
			<ConditionalTooltip content="Set up your account to fix log in issues." condition={isSetup}>
				<DefaultButton text="Issues signing in" onClick={issues} allowDisabledFocus disabled={window.location.hash == "#accountSetup"} />
			</ConditionalTooltip>
		</Stack>
		<LoginPanel isOpen={loginPanelOpen} dismiss={() => setLoginPanelOpen(false)} />
	</Stack>;
}